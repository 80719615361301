import React, { useEffect, useState } from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
} from '@material-ui/core';
import { useStyles } from './termsConditions.styles';
import {
  AGREE_TERMS_AND_CONDITIONS,
  TERMS_AND_CONDITIONS,
} from '../../../shared/utils/textConstants';
interface IProps {
  id: string;
  handleSetStepCanAdvance: (id: string, flag: boolean) => void;
}

const TermsConditionsForm = (props: IProps) => {
  const { id, handleSetStepCanAdvance } = props;
  const [checked, setChecked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    validateNextStep();
    // eslint-disable-next-line
  }, [checked]);

  const validateNextStep = () => {
    if (checked) {
      handleSetStepCanAdvance(id, true);
    } else {
      handleSetStepCanAdvance(id, false);
    }
  };

  const handleChange = () => {
    return checked ? setChecked(false) : setChecked(true);
  };

  return (
    <div>
      <div className={classes.title}>Terms and conditions</div>
      <br />
      <FormControl className={classes.formControl} component="fieldset">
        <TextField
          value={TERMS_AND_CONDITIONS}
          multiline
          rows={12}
          InputProps={{ disableUnderline: true }}
          className={classes.textfield}
        />
        <br />
        <FormControlLabel
          control={<Checkbox color="primary" onChange={handleChange} />}
          label={AGREE_TERMS_AND_CONDITIONS}
          className={classes.agree}
        />
      </FormControl>
    </div>
  );
};

export default TermsConditionsForm;
